@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

.ml_8 {
  margin-left: 8px;
}

.pt_24 {
  padding-top: 24px;
}

.pb_20 {
  padding-bottom: 20px;
}

.max_width_20 {
  max-width: 20px;
}

.main_container {
  max-width: 680px;
  /* background-color: rgba(80, 183, 48, 0.166); */
  /* background-color: white; */
  background-color: transparent;
  margin: 0 auto;
  text-align: center;
}

.wind_logo {
  margin-bottom: 34px;
  margin-top: 56px;
}

.welcome_container {
  /* background: linear-gradient(
    142deg,
    #fff4e3 0%,
    #f4f7e3 55.21%,
    #f1fcee 100%
  ); */
  background: linear-gradient(
    142deg,
    #fff4e3 0%,
    #f4f7e3 55.21%,
    #f1fcee 100%
  );
  /* background: url(https://i.ibb.co/8NGdHRv/card-bg.png) no-repeat; */
  background-image: url(https://i.ibb.co/8NGdHRv/card-bg.png);
  background-repeat: no-repeat;
  border-radius: 24px;
  width: 640px;
  height: 640px;
  position: relative;
  overflow: hidden;
  margin: 0 20px;
  margin-bottom: 64px;
  text-align: center;
}

.user_name {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin-top: 72px;
  margin-bottom: 16px;
}

.welcome_message {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 16px;
}

.welcome_info {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #75808a;
  /* width: 350px; */
  padding: 0 150px;
  margin: 0px;
}

.welcome_container2 {
  width: 822px;
  height: 822px;
  border-radius: 50%;
  background: linear-gradient(
    269.53deg,
    #feedef -3.65%,
    #fef5e8 50.86%,
    #fef1d7 108.88%
  );
  /* margin-left: -91px; */
}

/* .welcome_container2_position {
  position: absolute;
  left: 19%;
  right: 19%;
} */

.welcome_container2_text {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  /* width: 397px; */
  /* padding: 0 122px; */
  padding: 0 140px;
  /* padding-top: 100px; */
  /* margin-top: 100px; */
  padding-top: 154px;
  /* margin-bottom: 24px; */
  /* margin-left: 213px; */
  padding-bottom: 24px;
}

.welcome_container2_btn {
  width: 200px;
  height: 60px;
  border: none;
  background: linear-gradient(68.42deg, #6e50ff 15.98%, #ff50ba 96.19%);
  border-radius: 8px;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  padding: 18px 52px;
}

.card1_container {
  width: 592px;
  height: 248px;
  background: #f2f9ff;
  border-radius: 24px;
  display: flex;
  /* flex-direction: row;
    align-items: center; */
  padding: 32px 24px;
  margin: 16px 20px;
}

.card1_container_aside1 {
  width: 50%;
}

.card1_container_aside1 > img {
  max-width: 250px;
}

.card1_container_aside2 {
  width: 50%;
  text-align: left;
}

.card2_container {
  display: flex;
  width: 640px;
  margin: 0 20px;
}

.card2_container2 {
  width: 264px;
  height: 264px;
  background: #f2f9ff;
  border-radius: 24px;
  padding: 24px;
  margin-top: 0;
  margin-bottom: 16px;
  position: relative;
  text-align: left;
}

.card_title {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin: 0;
  margin-bottom: 8px;
}

.card_desc {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #75808a;
  margin: 0;
  margin-bottom: 16px;
}

.card_link {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #6e50ff;
  display: flex;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
}

.card_link_arrow_container {
  margin-left: 8px;
}

.card_link_arrow_icon {
  max-width: 20px;
}

.card_img1 {
  /* position: absolute;
  right: 8px;
  bottom: 0px; */
  text-align: right;
}

.card_img2 {
  /* position: absolute;
  right: 24px;
  bottom: 24px; */
  text-align: right;
  /* margin-top: 24px; */
  margin-top: 8px;
}

.card3_container {
  width: 640px;
  height: 186px;
  background: linear-gradient(
    269.53deg,
    #feedef -3.65%,
    #fef5e8 50.86%,
    #fef1d7 108.88%
  );
  border-radius: 24px;
  margin: 0 20px;
  margin-top: 48px;
  margin-bottom: 32px;
}

.card3_mail_btn {
  width: 200px;
  height: 60px;
  border: 2px solid #000000;
  background-color: transparent;
  border-radius: 8px;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333231;
  padding: 18px 26px;
  cursor: pointer;
  text-decoration: none;
}

.description {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #75808a;
  max-width: 548px;
  margin: 0 auto;
  margin-top: 32px;
}

.description_link {
  color: #6e50ff;
  text-decoration: none;
}

.description_link:hover {
  text-decoration: underline;
}

.group_btn_container {
  margin: 0 48px;
  margin-top: 32px;
  margin-bottom: 16px;
}

.group_btn {
  width: 236px;
  height: 32px;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  display: flex;
  padding: 20px 24px;
  margin-right: 8px;
  text-decoration: none;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: 32px;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.group_btn > img {
  margin-right: 16px;
}

.line {
  margin: 32px 20px;
  width: 638px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.05);
}

.social_icon_container {
  margin-top: 16px;
  margin-bottom: 64px;
  text-align: center;
}

.social_icon {
  margin: 0 12px;
  text-decoration: none;
}